import React from 'react'
//import PropTypes from 'prop-types'
// Components
import { Link, graphql } from 'gatsby'

import PostGrid from '../components/PostGrid'
import Layout from '../components/Layout'
// import '../components/PostGrid.css'

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark

  const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } tagged with “${tag}”`
  return (
    <Layout>
      <main className="main-body">
        <section className="section">
          <div className="container">
            <div>
              <h3>{tagHeader}</h3>
              <div className="section">
                <PostGrid
                  posts={edges.map((post) => ({
                    ...post.node,
                    ...post.node.frontmatter,
                    ...post.node.fields
                  }))}
                />
              </div>
            </div>
            <div className="section">
              <div className="Button PostGrid--Loader taCenter">
                <Link to="/tags">
                  <button className="Button">View All Tags</button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default Tags
export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            excerpt
            date
            categories {
              category
            }
            featuredImage
          }
        }
      }
    }
  }
`
